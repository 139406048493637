<template>
  <div class="form-rounded-inputs">
    <pull-refresh ref="scroller" :on-refresh="fnRefresh" :showPullRefresh='stepNum == null'>

      <div class="row justify-content-between align-items-end">
        <div class="col-12 col-xl-6">
          <v-observer tag="form" ref="formPagination" @submit.prevent="fnResetPagination()">
            <div class="row">
              <div class="col-12 col-lg-4 ml-auto ">
                <div class="form-group">
                  <div class="row align-items-end">
                    <div class="col">
                      <label v-text=" $t('dashboard.stores.name') ">
                      </label>
                      <div class="input-group">
                        <input type="text" class="form-control" placeholder="Ecart" v-model="pagination.name "
                          @change=" fnResetPagination() ">
                        <div class="input-group-append">
                          <button class="btn btn-success"> <i class="fa fa-search"></i> </button>
                        </div>
                      </div>
                    </div>
                    <div class="col-auto d-md-none" v-if="mobileVersion">

                      <div class="row">
                        <div class="col-auto pl-0">
                          <button type="button" v-if="mobileVersion" @click="showAdvancedFilters =! showAdvancedFilters"
                            class="btn btn-info circle-35">
                            <transition name="custom-classes-transition" mode="out-in"
                              enter-active-class="animated jello">
                              <i class=" fa fa-sliders-h" v-if="!showAdvancedFilters" key="open"></i>
                              <i class=" fa fa-times" v-if="showAdvancedFilters" key="close"></i>
                            </transition>
                          </button>
                        </div>
                        <div class="col-auto pl-0" v-if="userSession.store_id">
                          <b-dropdown variant="success circle-35" block menu-class="w-100" right
                            :text="$t('general.newStore')" v-if=" !integration " no-caret>
                            <template v-slot:button-content>
                              <i class="fa fa-plus-circle fa-lg d-md-none"></i>
                              <span v-text="$t('general.newStore')" class="d-none d-md-block"></span>
                            </template>
                            <b-dropdown-item @click="fnApiCreateStore()" v-if="userSession.store_id == null">
                              <span v-text="$t('general.button.createStore')"></span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="fnGlobalStoreIntegrationLink(true,device)">
                              <span v-text="$t('general.button.integrateNewStore')"></span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6 col-sm-6 col-lg-4 " v-show="showAdvancedFilters">
                <div class="form-group animated fadeIn ">
                  <label for="" v-text="$t('tables.ecommerce')"></label>
                  <div class="input-group">
                    <select class="custom-select" v-model="pagination.ecommerce " @change="fnResetPagination() ">
                      <option :value="null" v-text="$t('general.form.all')"> </option>
                      <option :value="ecommerce.id" v-for=" (ecommerce,index) in ecommerceList " :key="index"
                        v-text=" ecommerce.id "> </option>
                    </select>
                    <div class="input-group-append">
                      <button class="btn btn-success">
                        <i class="fa fa-store"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6 col-sm-6 col-lg-4 " v-show="showAdvancedFilters">

                <div class="form-group animated fadeIn ">
                  <label for="" v-text="$t('dashboard.stores.status')"></label>
                  <div class="input-group">
                    <select class="custom-select" v-model="pagination.active " @change="fnResetPagination() ">
                      <option :value="null" v-text="$t('general.form.all')"> </option>
                      <option :value="true"> Online </option>
                      <option :value="false"> Offline </option>
                    </select>
                    <div class="input-group-append">
                      <button class="btn btn-success">
                        <i class="fa fa-ellipsis-h"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-6 col-sm-6 col-lg-4 d-none  ">
                <div class="form-group animated fadeIn">
                  <label for=""> <i class="fa fa-arrows-v"></i> Limit </label>
                  <select class=" form-control " v-model="pagination.limit " @change="fnResetPagination(); ">
                    <option :value="limit" v-for=" (limit,index) in CONST.PAGINATION " :key="index" v-text=" limit ">
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </v-observer>
        </div>
        <div class="col-12 col-lg-4 col-xl-2 ml-auto d-none d-md-block" v-if=" !integration ">
          <div class="form-group">
            <b-dropdown variant="success" block menu-class="w-100" right :text="$t('general.newStore')" no-caret>
              <template v-slot:button-content>
                <i class="fa fa-plus fa-lg d-md-none"></i>
                <span v-text="$t('general.newStore')" class="d-none d-md-block"></span>
              </template>
              <b-dropdown-item @click="fnApiCreateStore()" v-if="userSession.store_id == null">
                <span v-text="$t('general.button.createStore')"></span>
              </b-dropdown-item>
              <b-dropdown-item @click="fnGlobalStoreIntegrationLink(true,device)">
                <span v-text="$t('general.button.integrateNewStore')"></span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>

      <div>
        <div v-show=" getStoresList.length > 0 && !loadingData ">
          <!-- Mobile -->
          <div class=" d-md-none ">
            <table class="table ecart-table">
              <thead class=" ">
                <tr>
                  <th v-text=" $t('dashboard.stores.title') "> </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for=" (store,index ) in getStoresList " :key=" index "
                  :class=" store.active ? '' : 'item-offline'">
                  <td>
                    <div class="row">
                      <div class="col-auto">
                        <!-- <pre> {{store.image}} </pre> -->
                        <router-link :to=" '/dashboard/stores/' + store.id  ">
                          <img v-image=" store.image " class=" img-contain-50 "
                            :class="store.ecommerce == 'Ecart' ? 'bg-white' : '' ">
                        </router-link>
                      </div>
                      <div class="col">
                        <div class="row align-items-center">
                          <div class="col-auto">
                            <p class=" m-0 f-w-600">
                                <span class="text-muted" v-text="'ID: '"></span>
                                <span v-text="store.store_id"></span>
                            </p>
                          </div>
                          <div class="col text-right">
                            <b-dropdown variant="success circle-30" no-caret right>
                              <template v-slot:button-content class="m-0">
                                <i class="fa fa-ellipsis-h fa-lg"></i>
                              </template>
                              <b-dropdown-item :to=" '/dashboard/stores/' + store.id ">
                                <p>
                                  <i class="fab fa-sm fa-wpforms mr-2"></i>
                                  <span v-text=" $t('general.details') "></span>
                                </p>
                              </b-dropdown-item>
                              <b-dropdown-item :href=" CONST.ECART.STORE + 's/store/' + store.id " target="_blank">
                                <p>
                                  <i class="fa fa-sm fa-external-link-alt mr-2"></i>
                                  <span v-text="$t('tables.viewInStore')"></span>
                                </p>
                              </b-dropdown-item>
                              <b-dropdown-divider></b-dropdown-divider>
                              <b-dropdown-item @click="fnSyncStore( store )" v-if=" store.ecommerce != 'Ecart'  ">
                                <p>
                                  <i class="fa fa-sm fa-sync mr-2"></i>
                                  <span v-text="$t('dashboard.stores.sync')"></span>
                                </p>
                              </b-dropdown-item>
                              <b-dropdown-item @click=" fnApiChangeStatusStore(index) ">
                                <p>
                                  <i class="fa fa-sm fa-power-off mr-2"></i>
                                  <span
                                    v-text=" !store.active ? $t('general.button.activate') : $t('general.button.deactivate') "></span>
                                </p>
                              </b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </div>
                        <p class="m-0">
                          <router-link class="btn btn-sm btn-success px-3" :to=" '/dashboard/stores/' + store.id  ">
                            <strong v-text=" store.name || fnStoreURL(store.url) || store.store_id ">
                            </strong>
                          </router-link>
                        </p>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Desktop -->
          <div class=" d-none d-md-block">
            <table class="table ecart-table">
              <thead class="  ">
                <tr class=" ">
                  <th class=" text-center " v-text="$t('tables.ecommerce') "> </th>
                  <th class=" text-center " v-text=" $t('dashboard.stores.storeName') "> </th>
                  <th class=" text-center " v-text=" $t('dashboard.stores.phone') "> </th>
                  <th class=" text-right col" v-text=" $t('general.actions') "> </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for=" (store,index ) in getStoresList " :key=" index "
                  :class=" store.active ? '' : 'item-offline'">
                  <td class="text-center">
                    <router-link :to=" '/dashboard/stores/' + store.id ">
                      <img v-image=" store.image " class=" img-contain-40 "
                        :class="store.ecommerce == 'Ecart' ? 'bg-white' : '' ">
                    </router-link>
                  </td>
                  <td class=" text-center ">
                    <p class=" m-0 ">
                      <small>
                        <span v-text="'ID: '"></span>
                        <strong class="f-w-600" v-text="store.store_id"></strong>
                      </small>
                    </p>
                    <b-button :to=" '/dashboard/stores/' + store.id " pill variant="success" size="sm" class="px-3">
                      <p class="text-truncate m-0">

                        <strong :class=" store.active ? '': 'text-warning' "
                          v-text=" store.name || fnStoreURL(store.url) || store.store_id  ">
                        </strong>
                      </p>
                    </b-button>
                  </td>
                  <td class="text-center">
                    <p class="f-w-500 m-0" v-text=" (store.phone)? store.phone : '--' "
                      :class=" (store.phone)? '' : 'text-muted' ">
                    </p>
                  </td>
                  <td class="text-right">
                    <b-dropdown variant="success circle-30" :text="$t('general.actions')" right no-caret>
                      <template v-slot:button-content class="m-0">
                        <i class="fa fa-ellipsis-h"></i>
                      </template>
                      <b-dropdown-item :to=" '/dashboard/stores/' + store.id ">
                        <p>
                          <i class="fab fa-sm fa-wpforms mr-2"></i>
                          <span v-text=" $t('general.details') "></span>
                        </p>
                      </b-dropdown-item>
                      <b-dropdown-item :href=" CONST.ECART.STORE + 's/store/' + store.id " target="_blank">
                        <p>
                          <i class="fa fa-sm fa-external-link-alt mr-2"></i>
                          <span v-text="$t('tables.viewInStore')"></span>
                        </p>
                      </b-dropdown-item>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-item @click="fnSyncStore( store )" v-if=" store.ecommerce != 'Ecart'  ">
                        <p>
                          <i class="fa fa-sm fa-sync mr-2"></i>
                          <span v-text="$t('dashboard.stores.sync')"></span>
                        </p>
                      </b-dropdown-item>
                      <b-dropdown-item @click="fnApiChangeStatusStore(index) ">
                        <p>
                          <i class="fa fa-sm fa-power-off mr-2"></i>
                          <span
                            v-text=" !store.active ? $t('general.button.activate') : $t('general.button.deactivate') "></span>
                        </p>
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>

              </tbody>
            </table>
          </div>
        </div>
        <no-data v-show=" getStoresList.length == 0 || loadingData " :isLoading="loadingData"
          :dataMessage="$t('dashboard.stores.integration.message')" @btn-fn=" changeModal({ newStore: true }); "
          :btnMessage=" $t('general.newStore') ">
        </no-data>
        <div v-if="pagination.total > 0 && ( (pagination.total / pagination.limit) > 1 ) ">
          <b-pagination v-model="pagination.page" :total-rows="pagination.total" :per-page="pagination.limit"
            @input="fnApiGetStores" align="fill" class="d-none d-md-flex" :first-text="$t('general.button.first')"
            :prev-text="$t('general.button.previous')" :next-text="$t('general.button.next')"
            :last-text="$t('general.button.last')">
          </b-pagination>
          <b-pagination v-model="pagination.page" :total-rows="pagination.total" :per-page="pagination.limit"
            @input="fnApiGetStores" align="fill" class="d-md-none"
            v-if="pagination.total > 0 && ( (pagination.total / pagination.limit) > 1 ) ">
          </b-pagination>
        </div>
      </div>
    </pull-refresh>
    <StoresReintegration />
  </div>
</template>

<script>
  import {
    mapState,
    mapMutations,
    mapActions,
    mapGetters,
  } from "vuex";
  import PullRefresh from "@/components/PullRefresh.vue";
  import StoresReintegration from "./stores-reintegration";
  export default {
    components: {
      PullRefresh,
      StoresReintegration,
    },
    data() {
      return {
        showAdvancedFilters: false,
      };
    },
    computed: {
      ...mapState(['mobileVersion', 'integration', 'device', 'userSession']),
      ...mapState(['menu', 'stepNum']),
      ...mapGetters('stores', ['getStoresList']),
      ...mapState('stores', ['logoList', 'ecommerceList', 'pagination',
        'loadingData'
      ]),
    },
    watch: {
      mobileVersion() {
        this.fnMobileUI();
      }
    },
    methods: {
      fnMobileUI() {
        if (this.mobileVersion) {
          this.showAdvancedFilters = false;
        } else {
          this.showAdvancedFilters = true;
        }
      },
      ...mapActions('stores', ['fnResetPagination', 'fnApiGetStores',
        'fnApiChangeStatusStore', 'fnFirstPagination', 'fnApiGetCountStores', 'fnApiCreateStore'
      ]),
      ...mapMutations('stores', ['changeModal']),
      ...mapActions('syncStore', ['fnSyncStore']),

      fnRefresh(done) {
        this.fnFirstPagination();
        done();
      },

    },
    mounted() {
      this.fnMobileUI();
    }
  }
</script>

<style lang="scss" scoped>
  .img-sm-logo {
    width: 17px;
    height: 17px;
  }
</style>