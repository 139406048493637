<template>
    <div>
        <b-modal :title=" $t('messages.integrationExpired') " content-class="card card-ecart form-rounded-inputs"
            header-class="card-header p-3 " centered v-model="modal.integrationExpired"
            size="md" hide-header-close>
            <p v-text="$t('messages.integrationExpiredMessage')"></p>
            <hr>
            <div class="row">
                <div class="col-auto">
                    <img v-image=" storeInformation.image " class=" img-contain-50 " alt="">
                </div>
                <div class="col pl-0">
                    <p>
                        <strong v-text="$t('tables.store') + ': ' "></strong>
                        <span v-text="storeInformation.name || fnStoreURL(storeInformation.url) || storeInformation.store_id"></span>
                    </p>
                    <p>
                        <strong class="f-w-600" v-text="$t('tables.ecommerce') + ': ' "></strong>
                        <span v-text="storeInformation.ecommerce"></span>
                    </p>
                </div>
            </div>
            <template v-slot:modal-footer>
                <div class="d-flex jusify-content-end ">
                    <b-button variant="none" class="mr-2"
                        @click=" changeModal({integrationExpired:false})"
                        v-text=" $t('general.button.close') ">
                    </b-button>
                    <b-button variant="success" @click=" fnGlobalStoreIntegrationLink(true,device,storeInformation.ecommerce) " v-text="$t('general.button.next')">
                    </b-button>
                </div>
            </template>
        </b-modal>
    </div>
</template>

<script>
    import {
        mapMutations,
        mapState
    } from 'vuex'
    export default {
        data() {
            return {}
        },
        computed: {
            ...mapState(['device']),
            ...mapState('syncStore', ['storeInformation', 'modal']),
        },
        methods: {
            ...mapMutations('syncStore', ['changeModal'])
            //   ...mapActions('syncStore', ['fnSyncStore'] ),
        }
    }
</script>

<style>

</style>